<template>
  <div class="home">
    <div class="card">
      <div class="card-body">
        <div class="header">
          <img src="images/kejaksaan_logo.png">
          <p>Portal Aplikasi Internal</p>
          <p>Kejaksaan Negeri Barito Kuala</p>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <p>Keberadaan Aplikasi Internal Kejaksaan Negeri Barito Kuala merupakan wujud dari
                keinginan
                bersama
                untuk
                memberikan informasi yang lebih lengkap dan bermanfaat bagi masyarakat Kabupaten Barito Kuala,
                masyarakat
                Indonesia maupun masyarakat Mancanegara.</p>
            </div>
            <div class="col-lg-6 col-sm-12">
              <p>Kini Kejaksaan Negeri Barito Kuala bersama Developer Adhyaksa Creative siap melangkah
                lebih
                maju
                lagi
                dengan
                semangat, inovasi, integritas, optimisme dan komitmen untuk memberikan yang terbaik bagi masyarakat
                Kabupaten Barito Kuala.</p>
            </div>
          </div>
        </div>
        <section class="apps">
          <div v-on:click="redLink('https://kejari-baritokuala.online/cs/login')">
            <span>
              <b><i class="fa-solid fa-users-rectangle"></i></b>
              <p class="mt-3">Customer Service</p>
            </span>
          </div>
          <div v-on:click="redLink('https://kejari-baritokuala.online/din/login')">
            <span>
              <b><i class="fa-solid fa-map-location-dot"></i></b>
              <p class="mt-3">Peta Digital</p>
            </span>
          </div>
          <div v-on:click="redLink('https://kejari-baritokuala.online/drive/login')">
            <span>
              <b><i class="fa-solid fa-hard-drive"></i></b>
              <p class="mt-3">AdhyaksaDrive</p>
            </span>
          </div>
          <div v-on:click="redLink('https://kejari-baritokuala.online/lke/login')">
            <span>
              <b><i class="fa-solid fa-file-pen"></i></b>
              <p class="mt-3">LKE</p>
            </span>
          </div>
          <div v-on:click="redLink('https://kejari-baritokuala.online/media-center/login')">
            <span>
              <b><i class="fa-solid fa-newspaper"></i></b>
              <p class="mt-3">Media Center</p>
            </span>
          </div>
          <div v-on:click="redLink('https://kejari-baritokuala.online/ptsp')">
            <span>
              <b><i class="fa-solid fa-elevator"></i></b>
              <p class="mt-3">PTSP</p>
            </span>
          </div>
        </section>
        <div class="pptc">
          <router-link class="privacy-policy" to="/welcome/privacy-policy">Privacy Policy</router-link> &
          <router-link class="terms-conditions" to="/welcome/terms-conditions">Terms Conditions</router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomeView',
    data() {
      return {
        news: [],
        activities: [],
        menus: []
      }
    },
    mounted() {},
    methods: {
      redLink: (link) => {
        window.location.replace(link)
      }
    }
  }
</script>