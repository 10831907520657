import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermsConditions from '../views/TermsConditions.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/welcome',
    name: 'home',
    component: Home
  },
  {
    path: '/welcome/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/welcome/terms-conditions',
    name: 'TermsConditions',
    component: TermsConditions
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
